<template>
  <Dialog
    header="Forgot password"
    v-model:visible="forgotPasswordDialog"
    :modal="true"
  >
    <p>
      If you forgot your password, please use our helpdesk to contact us. <br />
      <br />
      <a
        href="https://helpdesk.steiner.cz/index.php"
        target="_blank"
        rel="noopener noreferrer"
      >
        https://helpdesk.steiner.cz/index.php
      </a>
      <br />
      <br />
      Log in, click on the tab “Requests”, then on the tab “Insert request”.
      Fill out the fields marked with a red asterisk and then press “Submit
      ticket”. <br />
      <br />
      If you do not have a helpdesk account, contact us at
      <a href="mailto:atlantaadm@steiner.cz"> atlantaadm@steiner.cz </a>
      and we can help you with creating a new account”
    </p>
  </Dialog>
  <Message severity="info">
    Please check the authenticity and validity of application/server certificate
    before you login to prevent the Man-in-the-Middle attacks.
  </Message>
  <Message v-if="error" severity="error">{{ error }}</Message>

  <div class="" v-on:keyup.enter="login">
    <div style="width:400px; margin: auto">
      <fieldset v-if="!showCode" class="Donor">
        <label class="Donor">
          Atlanta
          <span style="float: right">TC web</span>
        </label>
        <label class="Donor" for="select">Registry</label>
        <select class="Donor" id="select" name="select">
          <option class="Donor" value="1" selected="selected">Finland</option>
        </select>

        <label class="Donor">E-mail</label>
        <InputText
          type="text"
          size="20"
          id="email"
          v-model="email"
          placeholder="Email.."
          class="Donor nofill"
          v-bind:class="{ 'p-invalid': !isEmail }"
        />
        <small v-if="!isEmail" id="username-help" class="p-error"
          >Please fill valid email.</small
        >
        <label class="Donor">Password</label>
        <InputText
          type="password"
          size="20"
          id="password"
          v-model="password"
          placeholder="Password.."
          class="Donor nofill"
          v-bind:class="{ 'p-invalid': !isPassword }"
        />
        <small v-if="!isEmail" id="password-help" class="p-error"
          >Please fill password.</small
        >

        <Button
          label="Sign in"
          style="width: 100%; margin: auto; margin-top: 20px; text-align: center"
          v-bind:disabled="!isSignIn"
          :onclick="login"
        />
        <div
          @click="forgotPasswordDialog = true"
          style="margin-top: 10px; color:#2196F3; cursor: pointer;"
        >
          Forgot password?
        </div>
      </fieldset>
      <fieldset v-if="showCode" class="Donor">
        <label class="Donor">Authentification code</label>
        <p>
          Please input the 6 digit code found in the Google Authenticator
          application
        </p>
        <InputMask
          id="code"
          ref="codeInput"
          v-model="code"
          slotChar="-"
          class="Donor nofill"
          mask="999 999"
          unmask
          v-bind:class="{ 'p-invalid': !isCode }"
        />
        <small v-if="!isCode" id="two-fac-help" class="p-error">
          Invalid Code try again
        </small>
        <Button
          label="Verify code"
          style="width: 100%; margin: auto; margin-top: 20px; text-align: center"
          v-bind:disabled="!isSignIn"
          :onclick="login"
        />
      </fieldset>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import InputText from "primevue/inputtext";
import Credentials from "@/model/credentials";
import Message from "primevue/message";
import Dialog from "primevue/dialog";
import InputMask from "primevue/inputmask";
import { namespace } from "vuex-class";

const currentUser = namespace("currentUser");

@Options({
  components: { Message, InputText, Dialog, InputMask },
  watch: {
    email: {
      handler: "validEmail",
      immediate: true
    },
    password: {
      handler: "validPassword",
      immediate: true
    }
  }
})
export default class LoginForm extends Vue {
  $refs!: {
    codeInput: HTMLFormElement;
  };
  email = "";
  // email = "tcweb@steiner.cz";
  password = "";
  // password = "ahoj";
  isEmail = true;
  isPassword = true;
  showCode = false;
  code = "";
  isCode = true;
  forgotPasswordDialog = false;

  @currentUser.State
  error!: string;

  @currentUser.Action
  fetchUser!: (cred: Credentials) => void;

  @currentUser.Mutation("SET_ERROR")
  setError!: (error: string) => void;

  public async login() {
    this.setError("");
    await this.fetchUser(
      new Credentials(this.email, this.password, +this.code)
    );
    if (localStorage.getItem("user")) {
      this.$router.push("/");
      localStorage.removeItem("code");
    } else if (localStorage.getItem("code")) {
      this.showCode = true;
      this.$nextTick(() => this.$refs.codeInput.$el.focus());
    }
  }

  mounted() {
    localStorage.removeItem("code");
  }

  validEmail() {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const b = re.test(String(this.email).toLowerCase());
    this.isEmail = b;
  }

  get isSignIn() {
    return this.isPassword && this.isEmail;
  }

  validPassword() {
    this.isPassword = !!this.password;
  }
}
</script>

<style scoped>
fieldset.Donor {
  max-width: 460px;
  padding: 30px 20px;
  border: 1px solid #cccccc;
  background: #f9f9f9;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
  box-shadow: #5e5e5e;
}

legend.Donor {
  font-weight: bold;
  background: #1c233f;
  padding: 15px 20px;
  border: 1px solid #394059;
  color: white;
  font-size: 20px;
  width: 100%;
}

label.Donor {
  display: block;
  float: left;
  color: #5f6a72;
  font-weight: bold;
  font-size: 16px;
  width: 100%;
  line-height: 40px;
  padding: 0;
  margin: 0;
}

input.Donor,
textarea.Donor {
  height: 40px;
  min-width: 100%;
  border: 1px solid #cccccc;
  border-radius: 3px;
  box-shadow: 0px 0px 5px 0px #e8e8e8 inset;
  font-size: 16px;
  text-indent: 10px;
}

select.Donor {
  height: 40px;
  min-width: 380px;
  border: 1px solid #cccccc;
  border-radius: 3px;
  padding: 5px 10px;
  background: #fff;
  font-size: 16px;
  color: #000000;
  -webkit-appearance: menulist;
  display: inline-block;
  line-height: 18px;
  background: linear-gradient(to bottom, #fff, #f1f1f1);
  background-image: -webkit-linear-gradient(to bottom, #fff, #f1f1f1);
  background-image: -moz-linear-gradient(to bottom, #fff, #f1f1f1);
  background-image: -o-linear-gradient(to bottom, #fff, #f1f1f1);
  background-image: linear-gradient(to bottom, #fff, #f1f1f1);
}
</style>
