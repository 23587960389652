export default class Credentials {
  user: string;
  password: string;
  code: number;

  constructor(user: string, password: string, code = 0) {
    this.user = user;
    this.password = password;
    this.code = code;
  }
}
