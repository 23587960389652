
import { Options, Vue } from "vue-class-component";
import InputText from "primevue/inputtext";
import Credentials from "@/model/credentials";
import Message from "primevue/message";
import Dialog from "primevue/dialog";
import InputMask from "primevue/inputmask";
import { namespace } from "vuex-class";

const currentUser = namespace("currentUser");

@Options({
  components: { Message, InputText, Dialog, InputMask },
  watch: {
    email: {
      handler: "validEmail",
      immediate: true
    },
    password: {
      handler: "validPassword",
      immediate: true
    }
  }
})
export default class LoginForm extends Vue {
  $refs!: {
    codeInput: HTMLFormElement;
  };
  email = "";
  // email = "tcweb@steiner.cz";
  password = "";
  // password = "ahoj";
  isEmail = true;
  isPassword = true;
  showCode = false;
  code = "";
  isCode = true;
  forgotPasswordDialog = false;

  @currentUser.State
  error!: string;

  @currentUser.Action
  fetchUser!: (cred: Credentials) => void;

  @currentUser.Mutation("SET_ERROR")
  setError!: (error: string) => void;

  public async login() {
    this.setError("");
    await this.fetchUser(
      new Credentials(this.email, this.password, +this.code)
    );
    if (localStorage.getItem("user")) {
      this.$router.push("/");
      localStorage.removeItem("code");
    } else if (localStorage.getItem("code")) {
      this.showCode = true;
      this.$nextTick(() => this.$refs.codeInput.$el.focus());
    }
  }

  mounted() {
    localStorage.removeItem("code");
  }

  validEmail() {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const b = re.test(String(this.email).toLowerCase());
    this.isEmail = b;
  }

  get isSignIn() {
    return this.isPassword && this.isEmail;
  }

  validPassword() {
    this.isPassword = !!this.password;
  }
}
